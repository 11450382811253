import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { Location } from '@angular/common';
import jsPDF from 'jspdf';
import { forkJoin } from 'rxjs';
import { PayrollService, PayrollServiceType } from 'src/app/services/hr/payroll/payroll.service';
import { UserService, UserServiceType } from 'src/app/services/hr/user/user.service';
@Component({
  selector: 'app-employee-payroll-detail',
  templateUrl: './employee-payroll-detail.component.html',
  styleUrls: ['./employee-payroll-detail.component.css']
})
export class EmployeePayrollDetailComponent implements OnInit {

  textStyle: string = "font-family: Arial, Helvetica, sans-serif !important;";

  userId: string;
  payrollId: string = '';
  payslipId: string = '';
  staffData: any;
  isLoading = false;
  payslipData: any;
  data!: any;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private _location: Location,
    private router: Router,
    private userService: UserService,
    private payrollService: PayrollService,
  ) {
    this.userId = this.router.url.split('/')[this.router.url.split('/').length - 4] || '';
    this.payrollId = this.router.url.split('/')[this.router.url.split('/').length - 2] || '';
    this.payslipId = this.router.url.split('/').pop() || '';
  }

  async ngOnInit() {
    await this.fetchPayslipDetail();
  }

  async fetchPayslipDetail() {
    this.isLoading = true;
    forkJoin({
      users: await this.userService.list(UserServiceType.USER_LIST, '', this.userId),
      payslip: await this.payrollService.list(PayrollServiceType.PAYSLIP, '', '', this.payrollId, this.payslipId)
    }).subscribe({
      next: async (res) => {
        this.staffData = res.users.data
        this.data = res.payslip.data;
        this.payslipData = res.payslip.data.components;

        // https://preserver-of-wisdom.vercel.app/docs/OnepassERP/process-flow/payroll/manage-payroll-flow
        this.data.payroll['endDate'] = new Date(this.data.payroll.endDate * 1000).setDate(new Date(this.data.payroll.endDate * 1000).getDate() - 1) / 1000;

        let nEarning = 0, nDeduction = 0;
        this.payslipData.map((item: any) => {
          if (item.payComponentConfig.type === 'EARNING') {
            nEarning++;
          }
          if (item.payComponentConfig.type === 'DEDUCTION') {
            nDeduction++;
          }
        })
        this.data['nEarning'] = nEarning;
        this.data['nDeduction'] = nDeduction;

        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandlerService.handleHttpError(err);
        this.isLoading = false;
      }
    })
  }

  onDetailClick(data: any) {

  }

  onExportClick() {
    const pdfContent = document.querySelector("#export") as HTMLElement;

    const doc = new jsPDF("portrait", "pt", "a4");
    doc.html(pdfContent,
      {
        margin: 20,
        html2canvas: {
          scale: 0.67,
        }
      })
      .then(() => {
        doc.save('Payslip_' + this.staffData.name + '.pdf');
      });
  }

  notification(input: string) {
    alert(input)
  }

  onBackClick() {
    this._location.back();
  }

}
