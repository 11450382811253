<div [class]="containerClass">
    <input 
        type="text"
        [placeholder]="placeholder"
        [formControl]="autocompleteCtrl"
        [matAutocomplete]="auto"
        [class]="inputClass"
        >
    <span 
        *ngIf="!disabled" 
        (click)="resetFilter()" 
        [class]="spanClass">
        <i class="bi bi-x-lg"></i>
    </span>
</div>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onItemSelected($event)" >
    <mat-option *ngFor="let item of list | async" [value]="item[attribute]">
        {{item[attribute] | translate}}
    </mat-option>
</mat-autocomplete>