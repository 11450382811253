import { UserService, UserServiceType } from 'src/app/services/hr/user/user.service';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { PermissionService } from '../services/permission/permission.service';
import { UserDetail } from '../interfaces/masterpass/staff';
import { TranslateService } from '@ngx-translate/core';
import { MasterpassService, MasterpassServiceType } from '../services/masterpass/masterpass.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  rememberMe = false;
  loginError = false;
  isLoading = false;
  passwordVisible = false;
  selectedLanguage = "en";
  languagesList: any = [];

  constructor(
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private masterpassService: MasterpassService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.translate.use("en");
  }

  /**
   * The ngOnInit function checks if the user is logged in and redirects them to the home page if they are.
   */
  ngOnInit(): void {
    // this.getLanguageList();
    if (this.authService.getUser()?.id) {
      this.router.navigate(['home']);
    }
  }

  /**
   * The `signIn` function is an asynchronous function that handles the sign-in process, including
   * authentication, error handling, and navigation.
   * @param {any} email - The `email` parameter is the email address entered by the user for signing in.
   * @param {any} password - The `password` parameter is the user's password that they entered during the sign-in process.
   */
  async signIn(email: any, password: any) {
    this.isLoading = true;
    await this.authService.login(email, password).toPromise().then(res => {
      this.authService.saveToken(res.data.accessToken);
      this.fetchUserData();

    }).catch(async err => {
      // this.errorHandler.handleHttpError(err);
      this.loginError = true;

      /* The `setTimeout` function is used to delay the execution of a function for a specified amount
      of time. In this case, it is used to set the `loginError` property to `false` after 5000
      milliseconds (5 seconds). This means that after 5 seconds, the login error message will be
      hidden. */
      setTimeout(() => {
        this.loginError = false;
      }, 5000);
    });
    this.isLoading = false;
  }

  async fetchUserData() {
    const request = await this.userService.list(UserServiceType.USER_PROFILE);
    request.subscribe({
      next: async (response: any) => {
        response.data['remember_me'] = this.rememberMe ? true : false;
        await this.authService.saveUser(response.data.user);
        await this.authService.saveUserOrganisation(response.data.organisation);
        await this.authService.getPermissions(response.data.user)

        setTimeout(() => {
          this.router.navigate(['home']);
        }, 500);
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(err);
      }
    })
  }

  async getLanguageList() {
    const req = await this.masterpassService.list(MasterpassServiceType.LANGUAGE_LIST);
    req.subscribe({
      next: (res) => {
        this.languagesList = res.data;
      },
      error: (e: HttpErrorResponse) => {
        this.errorHandler.handleHttpError(e);
      }
    })
  }

  onLanguageSelectChange(event: any) {
    this.translate.use(event.target.value)
  }

  /**
   * The function `onNavigateClick` navigates to a specified route, with an optional parameter to navigate to the dashboard route.
   * @param {string} route - The route parameter is a string that represents the route to navigate to.
   * It is typically a path or URL that corresponds to a specific component or page in the application.
   * @param [dashboard=true] - The `dashboard` parameter is a boolean value that determines whether the
   * route should be appended to the 'dashboard' route or not. If `dashboard` is `true`, the route will
   * be appended to the 'dashboard' route. If `dashboard` is `false`, the route will be navigated
   */
  onNavigateClick(route: string, dashboard = true) {
    if (dashboard) {
      this.router.navigate(['dashboard', route]);
    } else {
      this.router.navigate([route]);
    }
  }

  /**
   * The getCurrentYear function returns the current year.
   * @returns The current year.
   */
  getCurrentYear() {
    return new Date().getFullYear();
  }

}
