<div class="bg-white rounded" style="z-index: 1500;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
       <div class="modal-content">
          <div class="modal-header bg-info2 p-4 pb-2 text-primary">
             <h2 class="modal-title m-0" id="clockInLabel">
                {{'clocking.camera' | translate}}
             </h2>
             <button type="button" class="btn-close p-0 m-0" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close('cancel')"></button>
          </div>

          <div class="modal-body">
            <div class="row">
                <div class="col d-flex flex-row justify-content-center">

                    <div *ngIf="!showWebcam && !webcamImage" class="bg-light d-flex justify-content-center align-items-center" style="min-height: 400px; width: 100%;">
                        <app-loading-indicator></app-loading-indicator>
                    </div>

                    <webcam 
                        class="mb-1 bg-light"
                        *ngIf="showWebcam"
                        [trigger]="triggerObservable" 
                        [allowCameraSwitch]="allowCameraSwitch" 
                        [switchCamera]="nextWebcamObservable"
                        [videoOptions]="videoOptions"
                        [imageQuality]="0.92"
                        [captureImageData]="false"
                        (imageCapture)="handleImage($event)" 
                        (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)"></webcam>

                    <div class="snapshot mb-2" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col d-flex justify-content-center">
                    <button class="btn btn-primary me-2" 
                        (click)="triggerSnapshot();">
                        {{'clocking.capture-image' | translate}}
                    </button>
                    <button class="btn btn-outline-primary me-2" 
                        (click)="resetSnapshot();">
                        {{'clocking.reset-captured-image' | translate}}
                    </button>
                    <!-- <button class="btn btn-primary me-2" (click)="toggleWebcam();">Toggle Webcam</button> -->
                    <br/>
                    <!-- <button class="actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next Webcam</button>
                    <input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch"><label for="cameraSwitchCheckbox">Allow Camera Switch</label>
                    <br/>
                    DeviceId: <input id="deviceId" type="text" [(ngModel)]="deviceId" style="width: 500px">
                    <button (click)="showNextWebcam(deviceId);">Activate</button> -->
                </div>
            </div>

            <!-- <div class="row">
                <div class="col">
                    <div class="snapshot" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl"/>
                      </div>
                </div>
            </div> -->
          </div>

          <div class="modal-footer bg-light p-3">
            <button 
                style="min-width: 120px;" 
                class="btn btn-outline-primary mx-2 p-3 fw-bold" 
                (click)="closeModal()">
                    {{cancelLabel}}
            </button>
            <button 
                style="min-width: 120px;" 
                [disabled]="!webcamImage"
                class="btn btn-primary mx-2 p-3 fw-bold" 
                (click)="closeModalSubmitImage()">
                    {{submitLabel}}
            </button>
            </div>
        </div>
    </div>
</div>